<template>
  <div class="fullContainer">
    <padder big style="height: 100%">
      <div class="collaboratorsContainer">
        <h2 class="titleContainer">
          <span>{{ title }}</span>
        </h2>
        <div class="collaborators">
          <template v-for="collaborator in collaborators">
            <div>
              <Image :img="collaborator" sizes="400px" />
            </div>
          </template>
        </div>
      </div>
    </padder>
  </div>
</template>

<script>
export default {
  props: ["title", "collaborators"],
};
</script>

<style lang="scss" scoped>
.fullContainer {
  width: $max-width;
  max-width: 100%;
  margin: 0px auto;
}

.titleContainer {
  // @include bigFontSize;
  text-align: center;
}

.collaboratorsContainer {
  margin-bottom: 200px;
  overflow: hidden;
}

.collaborators {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  text-align: center;
  div {
    flex: 0 0 120px;
  }
  @media screen and (max-width: 800px) {
    gap: 40px;
  }
  @media screen and (max-width: 500px) {
    div {
      flex: 0 0 70px;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 400px) {
  .titleContainer {
    @include bigFontSize;
  }
}
</style>
