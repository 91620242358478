<template>
  <div
    id="carouselContainer"
    ref="carousel"
    :class="{ single: slides.length == 1 }"
    @click="triggerMove"
    @touchstart="touchstart"
    @touchend="touchend"
  >
    <div id="progressContainer">
      <button
        v-for="i in slides.length"
        @click.stop="goToSlide(i - 1)"
        class="progressElement"
      >
        <div class="progressInner">
          <div
            v-if="(currentSlide + slideProgress) % slides.length > i - 1"
            :style="{
              '--completion': Math.min(
                1,
                ((currentSlide + slideProgress) % slides.length) - (i - 1)
              ),
            }"
            class="innerProgress"
          ></div>
        </div>
      </button>
    </div>
    <template v-for="(slide, i) in slides">
      <Transition name="fade">
        <div :key="slide.text" v-show="currentSlide == i" :class="['slide']">
          <slot name="slide" :slide="slide" :visible="currentSlide == i" />
        </div>
      </Transition>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
    },
    frameTime: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      currentSlide: 0,
      showingCursor: false,
      slideStartTime: new Date().getTime(),
      cursorDirection: 1,
      cursorPosition: {
        x: 0,
        y: 0,
      },
      onLink: false,
      animFrame: null,
      slideProgress: 0,
    };
  },
  mounted() {
    this.$nextTick(this.updateLazy);
    this.resetTime();
    this.animFrame = requestAnimationFrame(this.updateTime);

    // setTimeout(this.updateLazy, 500)
  },
  beforeUnmount() {
    document.body.removeEventListener("keydown", this.handlekeydown);

    cancelAnimationFrame(this.animFrame);
  },
  methods: {
    updateTime() {
      this.slideProgress = Math.min(
        1,
        (new Date().getTime() - this.slideStartTime) / this.currentFrameTime
      );
      if (this.slideProgress == 1) {
        this.move(1);
      }
      this.animFrame = requestAnimationFrame(this.updateTime);
    },
    goToSlide(i) {
      this.currentSlide = i;
      this.resetTime();
    },
    move(dir) {
      this.currentSlide = Math.floor(
        (this.currentSlide + this.slides.length + dir) % this.slides.length
      );
      this.resetTime();
    },
    resetTime() {
      this.slideStartTime = new Date().getTime();
      this.slideProgress = 0;
    },
    triggerMove(e) {
        this.move(e.clientX > window.innerWidth / 2 ? 1 : -1);

    },

    touchstart(e) {
      this.cursorPosition.x = e.touches[0].clientX;
      if (e.touches.length == 2) {
        this.disableTouchNav = true;
      }
    },
    touchend(e) {
      e.preventDefault();
      if (e.changedTouches.length == 2) {
        return;
      }
      if (!this.disableTouchNav) {
        if (
          Math.abs(this.cursorPosition.x - e.changedTouches[0].clientX) > 20
        ) {
          if (this.cursorPosition.x < e.changedTouches[0].clientX) {
            this.move(-1);
          } else this.move(1);
        } else {
          this.triggerMove({
            clientX: e.changedTouches[0].clientX,
            clientY: e.changedTouches[0].clientY,
          });
        }
      } else if (e.touches.length == 0) {
        this.disableTouchNav = false;
      }
    },
  },
  computed: {
    currentFrameTime() {
      return Number(this.slides[this.currentSlide].slide_time) * 1000;
    },
  },
  watch: {
    currentSlide: {
      handler() {
        this.$nextTick(this.updateLazy);
      },
      immediate: true,
    },
    content: {
      handler() {
        this.$nextTick(this.updateLazy);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#carouselContainer {
  position: relative;
  width: 100%;
  // height: 50vh;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  z-index: 50;

  @media screen and (max-width: $breakpoint) {
    aspect-ratio: 1/1.22;
  }
}

.left,
.right {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: 1000;
}

.right {
  left: 50%;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  touch-action: manipulation;
  padding: 0px var(--padding);

  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  display: flex;
  flex-direction: column;
}

#cursor {
  position: absolute;
  top: 0px;
  left: 0px;
  display: none;
  color: white;
  mix-blend-mode: difference;
  font-size: 2rem;
  pointer-events: none;

  .positioner {
    transform: translate(-50%, -50%);
  }
}

.single {
  #cursor {
    visibility: hidden;
  }

  &#carouselContainer {
    cursor: default;
  }
}

@media screen and (hover: none) {
  #cursor {
    display: none !important;
  }

  #carouselContainer {
    cursor: default;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#progressContainer {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  display: flex;
  gap: 20px;
  z-index: 1000;
  height: 10px;
  // background-color: white;

  padding: 0px var(--padding);
  box-sizing: border-box;

  button {
    all: unset;
    flex: 1 0 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    transform: translateY(-18px);

    .progressInner {
      height: 4px;
      background-color: #545454;
      border-radius: 5px;
      overflow: hidden;

      .innerProgress {
        width: calc(var(--completion) * 100%);
        background-color: $volt;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint) {
  .slide {
    padding: 40px 0px 0px 0px;
  }

  #progressContainer {
    gap: 6px;
    top: 12px;
  }
}
</style>
