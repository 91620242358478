<template>
    <padder>
        <div id="pricingSchemes" v-if="isLoaded">
            <div class="pricePoint">
                <PricePoint :pricing="leftpricing" />
            </div>
            <div class="pricePoint">
                <PricePoint :pricing="centerpricing" />
            </div>
            <div class="pricePoint">
                <PricePoint :pricing="rightpricing" />
            </div>
        </div>
    </padder>
</template>

<script>
import PricePoint from './PricePoint.vue';
import { mapState } from 'vuex';
export default {
    components: { PricePoint },
    data() {
        return {

        }
    },
    methods: {
        getScheme(slug) {
            return {
                title: this.pricing_page[slug + "_title"],
                price: this.pricing_page[slug + "_pricepoint"],
                highlight: this.pricing_page[slug + "_pricepoint_highlight"],
                highlight_sub: this.pricing_page[slug + "_pricepoint_highlight_sub"],
                priceDetails: this.pricing_page[slug + "_pricepoint_extra"],
                points: this.pricing_page[slug + "_infos"],
                button_url:  this.pricing_page[slug + "_button_url"],
                button_text:  this.pricing_page[slug + "_button_text"],
            }
        },
    },
    computed: {
        ...mapState(["pricing"]),
        pricing_page() {
            return this.pricing[this.currLocale]
        },
        isLoaded() {
            return isNaN(this.pricing)
        },
        leftpricing() {
            return this.getScheme("left")
        },
        centerpricing() {
            return this.getScheme("middle")
        },
        rightpricing() {
            return this.getScheme("right")
        }
    }
}
</script>

<style lang="scss" scoped>
#pricingSchemes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    width: $max-width;
    max-width: 100%;
    margin: 0px auto;
}

.pricePoint {
    background-color: #f2f2f2;
    border-radius: 20px;
    min-width: none;
    overflow: hidden;
}

@media screen and (max-width: $breakpoint) {
    #pricingSchemes {
        grid-template-columns: 1fr;

    }
}
</style>