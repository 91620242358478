<template>
  <SubNavigation :sections="generateSections" />
  <anchor hash="abos" />
  <div id="pricing"
       class="navPadding">
    <div id="header_image">
      <div id="img_cont">
        <Image :img="pricing_page.intro_image"
               sizes="500px" />
        <TestCircle class="pricing">{{ pricing_page.text_on_image }}</TestCircle>
      </div>
    </div>
    <PricingSchemes />
    <anchor hash="register" />
    <Register />
  </div>
</template>

<script>
import PricingSchemes from "@/components/pricing/PricingSchemes"
import { mapState } from 'vuex';
import TestCircle from '../components/general/TestCircle.vue'
export default {
  components: { PricingSchemes, TestCircle },
  mounted() {
  },
  computed: {
    ...mapState(["pricing"]),
    pricing_page() {
      return this.pricing[this.currLocale]
    },
    generateSections() {
      return [
        { ref: "abos", text: this.pricing_page.header_1 },
        { ref: "register", text: this.pricing_page.header_2 }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
#pricing {
  margin-top: 50px;
}

#header_image {
  width: 1200px;
  max-width: 100%;
  // margin: 0px auto;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0px auto 110px auto;

  #img_cont {
    margin: 0px auto;
    position: relative;
    min-width: 0;
  }

  img {
    width: 600px;
    max-width: 100%;
    height: auto;
  }
}

.pricing {
  top: 20%;
  left: 0;
  transform: translateX(-20%);

  @media screen and (max-width: $breakpoint) {
    top: 50%;
    transform: translate(10px, -50%);
  }
}
</style>
