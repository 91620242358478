<template>
    <padder big>
        <div class="listContainer">
            <ul class="list">
                <slide-list-row v-for="row, i in list"
                                :row="row"
                                ref="row"
                                @closeAll="closeAll" />
            </ul>
        </div>
    </padder>
</template>

<style lang="scss" scoped>
$rowHeight: 55px;

.list {
    max-width: $reduced-width;
    list-style: none;
    margin: 0px auto;
    padding: 0px;

    li {
        border-top: 1px solid grey;

        &:last-child {
            border-bottom: 1px solid grey;
        }

        &.open {
            img {
                transform: rotate(180deg);
            }
        }
    }
}

</style>

<script>
import SlideListRow from './SlideListRow.vue'
export default {
    components: { SlideListRow },
    props: ["list"],
    data() {
        return {
            currHeight: 0,
            currSelected: -1
        }
    },
    methods: {
        closeAll() {
            // this.$refs.row.forEach(row => {
            //     row.activate(false);
            // })
        }
    }
}
</script>