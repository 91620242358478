<template>
  <div class="home" v-if="isNaN(home)">
    <NewsLink v-if="featuredNews" :news="featuredNews" />
    <IntroSlideshow :home="home_page" />
    <FunctionsLink :home="home_page" />
    <DemoAndSupportLink :home="home_page" />
    <h2 class="FAQ">{{ home_page.faq_section_title }}</h2>
    <SlideList :list="home_page.home_faq" />
    <GreenLink :to="home_page.faq_section_url" class="faq_button">{{
      home_page.faq_section_button
    }}</GreenLink>

    <TestimonialContainer :testimonials="home_page.home_testimonials" />
    <Collaborators
      class="homeCollaborators"
      v-for="colabList in home_page.collaborators"
      :title="colabList.title"
      :collaborators="colabList.logos"
    />
    <NewsletterSignup />
  </div>
</template>

<script>
// @ is an alias to /src
import AnimationContainer from "../components/general/AnimationContainer.vue";
import Collaborators from "../components/home/Collaborators.vue";
import TestimonialContainer from "../components/home/TestimonialContainer.vue";
import NewsletterSignup from "../components/general/NewsletterSignup.vue";
import HomeText from "../components/home/HomeText.vue";
import SlideList from "../components/general/SlideList.vue";

import Register from "../components/general/Register.vue";
import TestApps from "../components/general/TestApps.vue";
import NewsLink from "../components/home/NewsLink.vue";

import { mapState } from "vuex";
import IntroSlideshow from "../components/home/IntroSlideshow.vue";
import DemoAndSupportLink from "../components/home/DemoAndSupportLink.vue";
import FunctionsLink from "../components/home/FunctionsLink.vue";
export default {
  name: "Home",
  mounted() {},
  components: {
    AnimationContainer,
    TestimonialContainer,
    Collaborators,
    NewsletterSignup,
    HomeText,
    SlideList,
    Register,
    TestApps,
    NewsLink,
    IntroSlideshow,
    FunctionsLink,
    DemoAndSupportLink,
  },
  computed: {
    ...mapState(["home"]),
    home_page() {
      return this.home[this.currLocale];
    },
    featuredNews() {
      if (this.home_page.news)
        return this.home_page.news.find((e) => e.show_on_home == "on");
      else return false;
    },
    collaborators() {
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  @include defaultFontSize;
}

.inGrey {
  text-align: center;
}

h3.inGrey {
  margin-top: 0px;
  margin-bottom: 37px;
}

h2 {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  text-align: center;
}

h2.FAQ {
  margin-bottom: 45px;
}

.faq_button {
  margin: 38px auto 222px auto;
}

.homeCollaborators {
  margin-top: 150px;
  @media screen and (max-width: $breakpoint) {
    margin-top: 100px;
  }
}

@media screen and (max-width: $breakpoint) {
  .faq_button {
    margin-bottom: 93px;
  }
}
</style>
