<template>
    <div class="pricingContainer">
        <div class="columnContainer">
            <div>
                <h3>{{ pricing.title }}</h3>
                <ul>
                    <li v-for="point in pricing.points">{{ point.info }}</li>
                </ul>
            </div>
            <div>
                <div class="pricingHighlightContainer" v-if="pricing.highlight != ''">
                    <div class="priceHighlight">
                        <div class="textCenterer">
                            <span class="highlight">{{ pricing.highlight }}</span>
                            <span class="highlightsub">{{ pricing.highlight_sub }}</span>
                        </div>
                    </div>
                </div>

                <div class="price">
                    <div class="mainPrice" v-html="pricing.price" />
                    <div class="extraInfo" v-html="pricing.priceDetails" />
                </div>
                <div>
                    <GreenLink :to="pricing.button_url">{{ pricing.button_text }}</GreenLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["pricing"]
}
</script>

<style lang="scss">
.pricingContainer .greenButtonLink {
    font-size: $defaultFontSize;
    width: 100%;

    @media screen and (max-width: $breakpoint) {
        max-width: 270px;
    }
}
</style>
<style lang="scss" scoped>
.pricingContainer {
    position: relative;

    padding: 0px 15px 32px 15px;
    height: 100%;
    box-sizing: border-box;
    gap: 50px
}

.columnContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;
    margin: 0px auto;
    height: 100%;

}

.price {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricingHighlightContainer {
    height: 90px;
    position: relative;
}

.priceHighlight {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    right: 0px;
    background-color: $volt;
    box-shadow: 0px 3px 6px #00000029;

    .textCenterer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        text-align: center;

        &>span {
            display: block;
            font-weight: 400;
        }
    }


    .highlightsub {
        font-size: 1rem;
    }
}

h3,
.mainPrice {
    @include bigFontSize;
    font-weight: 500;
    text-align: center;
}

.mainPrice {
    // margin-top: 50px;
}

.extraInfo {
    text-align: center;
    @include defaultFontSize;
}

ul {
    @include defaultFontSize;
    margin: 0px;
    margin-top: 50px;
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    // padding: 0px;
    li {
        list-style: none;
        padding-left: 0px;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            transform: translate(-30px, 5px);
            height: 15px;
            width: 15px;
            background-image: url(~@/assets/icons/checkmark.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }

        // list-style-image: url(~@/assets/icons/checkmark.svg);

        // &::marker {
        //     url("@")
        // }

        // margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
}
</style>